import React from 'react';
import nextWithApollo from 'next-with-apollo';
import { getDataFromTree } from '@apollo/client/react/ssr';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import config from 'config';

const withApollo = (Component, { ssr = true, ...options } = {}) =>
  nextWithApollo(
    ({ initialState, ctx }) =>
      new ApolloClient({
        uri: config('/apollo/http/uri'),
        credentials: 'same-origin',
        cache: new InMemoryCache().restore(initialState || {}),
        ssrMode: Boolean(ctx),
      }),
    {
      /* eslint-disable react/prop-types, react/destructuring-assignment */
      render: ({ Page, props }) => (
        <ApolloProvider client={props.apollo}>
          <Page {...props} />
        </ApolloProvider>
      ),
    }
  )(Component, { getDataFromTree: ssr ? getDataFromTree : undefined, ...options });

export default withApollo;
