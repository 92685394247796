import React from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';

const Link = React.forwardRef(({ href, as, external, children, ...props }, ref) => {
  // Mailto links
  if (href.startsWith('mailto:')) {
    return (
      <button type="button" onClick={() => window.open(href)} {...props}>
        {children}
      </button>
    );
  }

  const isExternal = external || !href.startsWith('/');

  return isExternal ? (
    <a href={href} rel="noopener noreferrer" target="_blank" ref={ref} {...props}>
      {children}
    </a>
  ) : (
    <NextLink href={href} as={as}>
      <a ref={ref} {...props}>
        {children}
      </a>
    </NextLink>
  );
});

Link.propTypes = {
  href: PropTypes.string,
  as: PropTypes.string,
  children: PropTypes.node,
  external: PropTypes.bool,
};

Link.defaultProps = {
  href: '',
  as: undefined,
  children: null,
  external: false,
};

export default Link;
