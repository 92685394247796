import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Flex = React.forwardRef(({ as: Container, itemAs: Item, container, item, children }, ref) => (
  <Container className={cx('flex flex-wrap', container)} ref={ref}>
    {React.Children.toArray(children).map((x, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Item key={i} className={cx(item)}>
        {x}
      </Item>
    ))}
  </Container>
));

Flex.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  container: PropTypes.string,
  item: PropTypes.string,
  as: PropTypes.string,
  itemAs: PropTypes.string,
};

Flex.defaultProps = {
  container: '',
  item: 'w-full',
  as: 'div',
  itemAs: 'div',
};

export default Flex;
