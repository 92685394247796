import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Logo = ({ className }) => (
  <img
    src="https://res.cloudinary.com/globobeet/image/upload/w_400/v1594226648/flinchys/logo.png"
    alt="Flinchy's Logo"
    className={cx('block', className)}
  />
);

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: 'w-40',
};

export default Logo;
