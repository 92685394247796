import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-solid-svg-icons';
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { DateTime } from 'luxon';

import { useGlobal } from 'context/Global';
import useSettings from 'hooks/useSettings';
import Link from './Link';

const SiteTopBar = ({ className }) => {
  const { menuOpen, toggleMenu } = useGlobal();
  const [loading, settings] = useSettings();
  const { phone, reservationUrl } = settings;

  const day = DateTime.local().get('weekdayLong').toLowerCase();
  const [open, close] = settings.schedule?.[day] ?? [];

  const todayHours = React.useMemo(() => {
    if (!open) return 'Closed today';
    if (!close) return `Open today at ${open}`;
    return (
      <>
        Open today from {open} &ndash; {close}
      </>
    );
  }, [open, close]);

  if (loading) return null;

  return (
    <section className={cx('bg-gray-darker', className)}>
      <div className="flex items-center h-16">
        <div className="flex-grow flex justify-between items-center container">
          <p className="hidden lg:block">{todayHours}</p>
          <p className="font-medium text-white">
            <FontAwesomeIcon icon={faPhone} size="lg" className="text-gray mr-3" />
            {phone}
          </p>
          {reservationUrl && (
            <p className="font-medium lg:hidden">
              <Link href={reservationUrl} className="text-accent hover:text-accent-dark">
                Reserve a table
              </Link>
            </p>
          )}
        </div>
        <div className="flex-shrink-0 lg:hidden">
          <button type="button" className="bg-accent text-white w-16 h-16" onClick={toggleMenu}>
            <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} size="lg" />
          </button>
        </div>
      </div>
    </section>
  );
};

SiteTopBar.propTypes = {
  className: PropTypes.string,
};

SiteTopBar.defaultProps = {
  className: '',
};

export default SiteTopBar;
