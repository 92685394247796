import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from './Link';

const Button = ({ component: Component, className, children, ...props }) => (
  <Component
    className={cx(
      'inline-block rounded-md px-6 py-4 border-2 border-accent hover:border-accent-dark text-white',
      className
    )}
    {...props}
  >
    {children}
  </Component>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({ render: PropTypes.func.isRequired }),
  ]),
};

Button.defaultProps = {
  className: '',
  component: Link,
};

export default Button;
