import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useSettings from 'hooks/useSettings';
import Logo from './Logo';
import Flex from './Flex';
import Link from './Link';
import Button from './Button';

const SiteHeader = ({ className }) => {
  const [
    loading,
    { onlineOrderingUrl, reservationUrl, enableHighlightEventPage, highlightEventNavLabel },
  ] = useSettings();

  return (
    <header className={cx('hidden lg:block mt-2', className)}>
      <div className="container flex items-center">
        <div className="flex-shrink-0">
          <Link href="/">
            <Logo />
          </Link>
        </div>
        <div className="flex-grow flex items-center px-8 xl:px-12">
          <Flex container="-ml-8" item="pl-8">
            <Link href="/menu" className="text-lg text-white hover:text-gray">
              Menu
            </Link>
            <Link href="/events" className="text-lg text-white hover:text-gray">
              Events
            </Link>
            <Link href="/catering" className="text-lg text-white hover:text-gray">
              Catering
            </Link>
            {enableHighlightEventPage && (
              <Link href="/chili-cookoff" className="text-lg text-white hover:text-gray">
                {highlightEventNavLabel ?? 'Chili Cookoff'}
              </Link>
            )}
            <Link href="/newsletter" className="text-lg text-white hover:text-gray">
              Newsletter
            </Link>
            <Link
              href="https://store.modernimprintllc.com/flinchys/shop/home"
              className="text-lg text-white hover:text-gray"
            >
              Shop
            </Link>
            <Link href="/contact" className="text-lg text-white hover:text-gray">
              Contact
            </Link>
          </Flex>
        </div>
        {!loading && (
          <div className="flex-shrink-0 flex items-center">
            <Flex container="-ml-8 items-center" item="pl-8">
              {onlineOrderingUrl && (
                <Link
                  href={onlineOrderingUrl}
                  className="text-lg text-accent hover:text-accent-dark"
                >
                  Order Online
                </Link>
              )}
              {reservationUrl && <Button href={reservationUrl}>Make a Reservation</Button>}
            </Flex>
          </div>
        )}
      </div>
    </header>
  );
};

SiteHeader.propTypes = {
  className: PropTypes.string,
};

SiteHeader.defaultProps = {
  className: '',
};

export default SiteHeader;
