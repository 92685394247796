import React from 'react';
import { useQuery, gql } from '@apollo/client';

const QUERY = gql`
  query {
    allSiteSettings(first: 1) {
      id
      address
      phone

      onlineOrderingUrl
      reservationUrl
      enableHighlightEventPage
      highlightEventNavLabel

      description
      facebookUrl
      twitterUrl
      instagramUrl
      yelpUrl

      monOpen
      monClose
      tueOpen
      tueClose
      wedOpen
      wedClose
      thuOpen
      thuClose
      friOpen
      friClose
      satOpen
      satClose
      sunOpen
      sunClose
    }
  }
`;

const useSettings = () => {
  const { data, loading } = useQuery(QUERY);
  const settings = data?.allSiteSettings?.[0];

  const computedSettings = React.useMemo(() => {
    if (!settings) return {};

    return {
      ...settings,
      schedule: {
        monday: [settings.monOpen, settings.monClose],
        tuesday: [settings.tueOpen, settings.tueClose],
        wednesday: [settings.wedOpen, settings.wedClose],
        thursday: [settings.thuOpen, settings.thuClose],
        friday: [settings.friOpen, settings.friClose],
        saturday: [settings.satOpen, settings.satClose],
        sunday: [settings.sunOpen, settings.sunClose],
      },
    };
  }, [settings]);

  return [!settings && loading, computedSettings];
};

export default useSettings;
