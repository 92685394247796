import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faYelp } from '@fortawesome/free-brands-svg-icons';
import { DateTime } from 'luxon';
import cx from 'classnames';

import useSettings from 'hooks/useSettings';
import Logo from './Logo';
import Flex from './Flex';
import Link from './Link';

const SiteFooter = ({ className }) => {
  const [
    loading,
    {
      address,
      phone,
      onlineOrderingUrl,
      reservationUrl,
      enableHighlightEventPage,
      highlightEventNavLabel,
      facebookUrl,
      twitterUrl,
      instagramUrl,
      yelpUrl,
      schedule,
    },
  ] = useSettings();
  const year = DateTime.local().toFormat('yyyy');

  if (loading) return null;

  return (
    <footer
      className={cx('py-12 bg-gray-darker lg:bg-transparent text-center lg:text-left', className)}
    >
      <div className="container">
        <div className="flex -ml-12 pt-4">
          <div className="pl-12 hidden lg:block lg:w-3/5">
            <div className="flex">
              <div className="flex-shrink-0 -mt-4">
                <Link href="/">
                  <Logo />
                </Link>
              </div>
              <Flex containerAs="nav" container="pl-16 -mt-2" item="w-1/2 xl:w-2/5 pt-2">
                <Link href="/menu" className="text-lg text-white hover:text-gray">
                  Menu
                </Link>
                <Link href="/events" className="text-lg text-white hover:text-gray">
                  Events
                </Link>
                <Link href="/catering" className="text-lg text-white hover:text-gray">
                  Catering
                </Link>
                {enableHighlightEventPage && (
                  <Link href="/chili-cookoff" className="text-lg text-white hover:text-gray">
                    {highlightEventNavLabel ?? 'Chili Cookoff'}
                  </Link>
                )}
                <Link href="/newsletter" className="text-lg text-white hover:text-gray">
                  Newsletter
                </Link>
                <Link href="/contact" className="text-lg text-white hover:text-gray">
                  Contact
                </Link>
                {onlineOrderingUrl && (
                  <Link
                    href={onlineOrderingUrl}
                    className="text-lg text-accent hover:text-accent-dark"
                  >
                    Order Online
                  </Link>
                )}
                {reservationUrl && (
                  <Link
                    href={reservationUrl}
                    className="text-lg text-accent hover:text-accent-dark"
                  >
                    Make a Reservation
                  </Link>
                )}
              </Flex>
            </div>
          </div>
          <div className="pl-12 w-full lg:w-2/5">
            <Flex container="flex-wrap -ml-4 -mt-1 max-w-md" item="w-1/2 pl-4 pt-1">
              {Object.entries(schedule).map(([day, [open, close]]) => {
                let hours = 'Closed';
                if (open && close) {
                  hours = (
                    <>
                      {open} &ndash; {close}
                    </>
                  );
                } else if (open) {
                  hours = open;
                }

                return (
                  <dl className="flex items-center leading-relaxed" key={day}>
                    <dt className="w-12 text-sm uppercase tracking-wide">{day.substr(0, 3)}</dt>
                    <dd className="text-lg text-white">{hours}</dd>
                  </dl>
                );
              })}
            </Flex>

            <p className="text-lg leading-relaxed text-white mt-6">
              <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" className="text-gray mr-3" />
              {address}
            </p>
            <p className="text-lg leading-relaxed text-white mt-1">
              <FontAwesomeIcon icon={faPhone} size="1x" className="text-gray mr-3" />
              {phone}
            </p>
            <Flex container="-ml-6 mt-6 items-center justify-center lg:justify-start" item="pl-6">
              {facebookUrl && (
                <Link href={facebookUrl} className="text-white hover:text-gray">
                  <FontAwesomeIcon icon={faFacebook} size="lg" />
                </Link>
              )}
              {twitterUrl && (
                <Link href={twitterUrl} className="text-white hover:text-gray">
                  <FontAwesomeIcon icon={faTwitter} size="lg" />
                </Link>
              )}
              {instagramUrl && (
                <Link href={instagramUrl} className="text-white hover:text-gray">
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </Link>
              )}
              {yelpUrl && (
                <Link href={yelpUrl} className="text-white hover:text-gray">
                  <FontAwesomeIcon icon={faYelp} size="lg" />
                </Link>
              )}
            </Flex>
          </div>
        </div>
        <p className="mt-12 lg:mt-24 text-sm">&copy;{year} Flinchy&apos;s. All rights reserved.</p>
      </div>
    </footer>
  );
};

SiteFooter.propTypes = {
  className: PropTypes.string,
};

SiteFooter.defaultProps = {
  className: '',
};

export default SiteFooter;
