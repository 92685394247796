import React from 'react';
import { useRouter } from 'next/router';

const useRouteChange = (callback) => {
  const [mounted, setMounted] = React.useState(false);
  const router = useRouter();

  React.useEffect(() => setMounted(true), []);

  React.useEffect(() => {
    if (mounted) callback();
  }, [mounted, callback, router.asPath]);
};

export default useRouteChange;
