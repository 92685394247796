import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useOutsideClickRef from '@rooks/use-outside-click-ref';

import { useGlobal } from 'context/Global';
import useSettings from 'hooks/useSettings';
import useRouteChange from 'hooks/useRouteChange';
import Link from './Link';

const SiteMobileNav = () => {
  const { menuOpen, closeMenu } = useGlobal();
  const [
    loading,
    { onlineOrderingUrl, reservationUrl, enableHighlightEventPage, highlightEventNavLabel },
  ] = useSettings();

  const [menu] = useOutsideClickRef(closeMenu);
  useRouteChange(closeMenu);

  return (
    <AnimatePresence>
      {menuOpen && (
        <motion.nav
          key="mobileNav"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="border-t-4 border-accent bg-gray-darker py-8 fixed right-0 top-16 lg:hidden w-full sm:max-w-sm shadow-xl"
          ref={menu}
        >
          <Link href="/" className="block py-3 px-12 text-xl text-white">
            Home
          </Link>
          <Link href="/menu" className="block py-3 px-12 text-xl text-white">
            Menu
          </Link>
          <Link href="/events" className="block py-3 px-12 text-xl text-white">
            Events
          </Link>
          <Link href="/catering" className="block py-3 px-12 text-xl text-white">
            Catering
          </Link>
          {enableHighlightEventPage && (
            <Link href="/chili-cookoff" className="block py-3 px-12 text-xl text-white">
              {highlightEventNavLabel ?? 'Chili Cookoff'}
            </Link>
          )}
          <Link href="/newsletter" className="block py-3 px-12 text-xl text-white">
            Newsletter
          </Link>
          <Link
            href="https://store.modernimprintllc.com/flinchys/shop/home"
            className="block py-3 px-12 text-xl text-white"
          >
            Shop
          </Link>
          <Link href="/contact" className="block py-3 px-12 text-xl text-white">
            Contact
          </Link>
          {!loading && (
            <>
              {onlineOrderingUrl && (
                <Link href={onlineOrderingUrl} className="block py-3 px-12 text-xl text-accent">
                  Order Online
                </Link>
              )}
              {reservationUrl && (
                <Link href={reservationUrl} className="block py-3 px-12 text-xl text-accent">
                  Make a Reservation
                </Link>
              )}
            </>
          )}
        </motion.nav>
      )}
    </AnimatePresence>
  );
};

export default SiteMobileNav;
