import React from 'react';
import PropTypes from 'prop-types';
import { NextSeo } from 'next-seo';

import useSettings from 'hooks/useSettings';
import SiteTopBar from './SiteTopBar';
import SiteHeader from './SiteHeader';
import SiteMobileNav from './SiteMobileNav';
import SiteFooter from './SiteFooter';

const PageLayout = ({ title, children }) => {
  const { description } = useSettings();

  return (
    <div className="flex flex-col flex-grow">
      <NextSeo title={title} description={description} />
      <SiteTopBar className="flex-shrink-0 sticky top-0 z-10" />
      <SiteHeader className="flex-shrink-0 mb-2" />
      <main className="flex-grow">{children}</main>
      <SiteFooter className="flex-shrink-0" />
      <SiteMobileNav />
    </div>
  );
};

PageLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

PageLayout.defaultProps = {
  title: '',
  children: null,
};

export default PageLayout;
